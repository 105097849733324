import { IActionContext } from '@msdyn365-commerce/core';

export const createCanonicalUrl = (context: IActionContext, paths: string[], page: string) => {
    const canonicalDomain = context.requestContext.canonicalDomain;
    const sitePath = context.requestContext.sitePath ?? '';

    const url = `https://${canonicalDomain}${sitePath}/${paths
        .map(p => toSlug(p))
        .filter(s => s.length > 0)
        .join('/')}/${page}`;
    return url;
};

const toSlug = (text: string) => {
    const slug = text
        .toLowerCase()
        .replace(/[^A-Za-z\u00C0-\u017F0-9]/g, '-')
        .replace(/--+/g, '-')
        .replace(/^-/, '')
        .replace(/-$/, '');

    return slug;
};
